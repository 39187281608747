<template>
    <div v-if="isViewNotifications">
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <feather-icon  v-b-modal.push-notifiation-rules height="20px" width="20px" icon="InfoIcon" />
                    </b-col>
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" @input="fetchNotifications" class="d-inline-block mr-1"
                                placeholder="Search..." />
                            <AddEmailNotificaton :manage="isAddNotifications" :token="bearerToken" @fetchNotifications="fetchNotifications" :notificationTypes="notification_type"/>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <EmailNotification :show="show" :pushNotification="pushNotification.data || []" :manage="isAddNotifications" :token="bearerToken"
                @fetchNotifications="fetchNotifications" :notificationTypes="notification_type"/>
            <div class="mx-2 mt-1 mb-1">
                <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
                    <div class="dataTables_info mt-1"></div>
                    <pagination class="mt-1 mb-0" :data="pushNotification" :limit=2 @pagination-change-page="fetchNotifications">
                    </pagination>
                </div>
            </div>
        </b-card>
        <b-modal id="push-notifiation-rules" hide-footer size="md" centered title="Email Notification Rules">
            <div>1. <code>&lt;rank&gt;</code> -> Profile Rank</div>
            <div>2. <code>&lt;profileName&gt;</code> -> Profile Name</div>
            <div>3. <code>&lt;profileId&gt;</code> -> Profile Id</div>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BBreadcrumb,
    BModal,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        EmailNotification: () => import("@/views/components/notifications/EmailNotification.vue"),
        AddEmailNotificaton: () => import("@/views/components/notifications/AddEmailNotificaton.vue"),
        ToastificationContent,
        BBreadcrumb,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BModal,
    },
    data() {
        return {
            isAddNotifications: false,
            searchQuery: "",
            show: false,
            alertMessageToast: "",
            bearerToken: "",
            pushNotification: [],
            isViewNotifications: false,
            notification_type: [],
        };
    },
    mounted() {
        this.isAddNotifications = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "add_email_notifications");
        this.isViewNotifications = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "view_email_notifications");
        this.bearerToken = "Bearer " + localStorage.getItem("_t")
        this.fetchNotifications();
        this.fetchNotificationTypes();
    },
    methods: {
        async fetchNotifications(page = 1) {
            this.show = true;
            const payload = {};
            payload.search = this.searchQuery;
            try {
                const res = await axios.post(process.env.VUE_APP_API_URL + `/email-notifications?page=${page}`, payload, {
                    headers: {
                        Authorization: this.bearerToken,
                    },
                });
                this.pushNotification = res.data.notifications;
                this.show = false;
            } catch (error) {

            }
        },

        async fetchNotificationTypes(page = 1) {
            this.show = true;

            try {
                const res = await axios.post(
                    `${process.env.VUE_APP_API_URL}/notification-types?page=${page}`,
                    { search: this.searchQuery },
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.notification_type = res.data.notification_types.data;
            } catch (error) {
                console.error("Error fetching notification types:", error);
            } finally {
                this.show = false;
            }
        },
    },
};
</script>
